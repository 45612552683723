<template>
    <div
        class="table-sort-container"
        :class="{ disabled }"
        @click="
            this.disabled ? '' : (isShowModal = true),
                this.$setGaEvent('clickSort', 'click-TableTool')
        "
    >
        <span v-text="sortLabel" class="sort-label" />
        <span v-text="'排序'" />
        <span class="sort-icon" />
    </div>
    <SortModal
        v-model="isShowModal"
        :sortOptions="dropdownSortOptions"
        :sortByOptions="dropdownSortByOptions"
        :sortLabel="sortLabel"
        v-model:sort="sortValue"
        v-model:sortBy="sortByValue"
    />
</template>

<script>
import _ from 'lodash'
import SortModal from '@/components/table/SortModal.vue'

export default {
    name: 'TableSort',
    components: {
        SortModal
    },
    inheritAttrs: false,
    emits: ['update:sortBy', 'update:sort'],
    props: {
        columns: {
            type: Array,
            default: function () {
                return []
            }
        },
        sort: {
            type: String,
            default: ''
        },
        sortBy: {
            type: String,
            default: ''
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        sortValue: {
            get() {
                return this.sort
            },
            set(val) {
                this.$emit('update:sort', val)
            }
        },
        sortByValue: {
            get() {
                return this.sortBy
            },
            set(val) {
                this.$emit('update:sortBy', val)
            }
        },
        dropdownSortOptions: function () {
            return [
                {
                    label: '遞增',
                    value: 'asc'
                },
                {
                    label: '遞減',
                    value: 'desc'
                }
            ]
        },
        dropdownSortByOptions: function () {
            return _.uniqBy(
                this.columns
                    .filter((column) => column.sortEnabled)
                    .map((column) => {
                        return {
                            label: column.label,
                            value: column.key
                        }
                    }),
                (option) => option.value
            )
        },
        sortLabel: function () {
            const sortLabel =
                this.dropdownSortOptions.find(
                    (option) => option.value === this.sort
                )?.label ?? ''
            const sortByLabel =
                this.dropdownSortByOptions.find(
                    (option) => option.value === this.sortBy
                )?.label ?? ''
            return `${sortByLabel}(${sortLabel})`
        }
    },
    data() {
        return {
            isShowModal: false
        }
    }
}
</script>

<style lang="scss" scoped>
.table-sort-container {
    display: flex;
    align-items: center;
    color: $secondary-grey;
    font-size: 14px;
    cursor: pointer;
}

.sort-label {
    &:before {
        content: '照 ';
        font-weight: normal;
        color: $secondary-grey;
    }
    display: inline;
    color: $sixth-black;
    font-weight: 500;
    margin-right: 5px;

    @media screen and (max-width: 576px) {
        display: none;
    }
}

.sort-icon {
    display: inline-block;
    margin-left: 3px;
    width: 14px;
    height: 16px;
}
</style>
